import React from "react";
import logo from "./logo.svg";
import Map from "react-map-gl";
import maplibregl from "maplibre-gl";

function App() {
  return (
    <div style={{ width: "100%", height: "100%", background: "#666" }}>
      <Map
        style={{ width: "100vw", height: "100vh" }}
        mapLib={maplibregl}
        initialViewState={{
          longitude: 10.443162,
          latitude: 45.056565,
          zoom: 6,
        }}
        mapStyle="map-style.json"
      />
    </div>
  );
}

export default App;
